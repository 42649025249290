/* Center the sign in form on the screen */
.container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f4f4f4;
    font-family: 'Poppins';
  }
  
  /* Style for the sign in card */
  .card {
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 320px;

    .logo {
      width: 100%;
      height: auto;
      margin-bottom: 24px;
    }

    .headline {
        font-size: 24px;
        font-weight: bold;
        text-align: center;
    }

    .text {
      color: #333;
      margin-bottom: 12px;
      font-size: 14px;
      text-align: center;
  }
  }

  .card h2 {
    text-align: center;
    color: #333;
  }
  
  .inputField {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 1rem;
    box-sizing: border-box;
  }

  .button-group {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .button {
    width: 100%;
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    background-color: #3D94AF;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
  }
  
  .button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  
  .errorMessage {
    color: red;
    margin-bottom: 1rem;
    text-align: center;
  }
  